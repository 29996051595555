import React from "react"
import Layout from "../components/layout/CenteredLayout"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { path } from "../config/routes"

const NotFoundPage = () => (
  <Layout>
    <h6 className="text-uppercase text-muted mb-4">
      <FormattedMessage
        id="pages.404.errorHeader"
        defaultMessage="404 error"
        description="404 error header"
      />
    </h6>
    <h1 className="display-4 mb-3">
      <FormattedMessage
        id="pages.404.noPageHeader"
        defaultMessage="There’s no page here"
        description="404 error no page header"
      />{" "}
      <span role="img" aria-label="cry">
        😭
      </span>
    </h1>
    <p className="text-muted mb-4">
      <FormattedMessage
        id="pages.404.hereByAccidentParagraph"
        defaultMessage="Looks like you ended up here by accident?"
        description="404 error here by accident paragraph"
      />
    </p>
    <Link to={path("DASHBOARD")} className="btn btn-lg btn-primary">
      <FormattedMessage
        id="pages.404.returnToDashboardLink"
        defaultMessage="Return to your dashboard"
        description="404 error return to dashboard link"
      />
    </Link>
  </Layout>
)

export default NotFoundPage
